import React from 'react';

const TermsOfService = () => {
  return (
    <div className="container my-5">
      <h2 className="text-uppercase text-center mb-4" style={{ color: '#D4AF37' }}>Terms of Service</h2>
      <p style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
        Welcome to Safari Netics! By accessing our website and using our services, you agree to comply with the following terms and conditions.
      </p>

      <h5 className="mt-4">1. Acceptance of Terms</h5>
      <p>
        By using our services, you affirm that you are at least 18 years old and agree to these terms. If you do not agree, please refrain from using our services.
      </p>

      <h5 className="mt-4">2. Modifications</h5>
      <p>
        We reserve the right to modify these terms at any time. Your continued use of our services constitutes acceptance of the new terms. We will notify you of any significant changes.
      </p>

      <h5 className="mt-4">3. User Responsibilities</h5>
      <p>
        Users are responsible for maintaining the confidentiality of their account information and for all activities under their account. You agree to notify us immediately of any unauthorized use of your account.
      </p>

      <h5 className="mt-4">4. Prohibited Activities</h5>
      <p>
        You agree not to engage in any of the following prohibited activities:
      </p>
      <ul>
        <li>Using the service for illegal purposes.</li>
        <li>Interfering with or disrupting the security, integrity, or performance of our services.</li>
        <li>Attempting to gain unauthorized access to our services or systems.</li>
      </ul>

      <h5 className="mt-4">5. Limitation of Liability</h5>
      <p>
        Safari Netics will not be liable for any indirect, incidental, or consequential damages arising from your use of our services.
      </p>

      <h5 className="mt-4">6. Governing Law</h5>
      <p>
        These terms shall be governed by and construed in accordance with the laws of Kenya. Any disputes arising out of these terms will be resolved in the courts of Kenya.
      </p>

      <h5 className="mt-4">7. Contact Information</h5>
      <p>
        For any inquiries, please contact us at <a href="mailto:info@safarinetics.com" style={{ color: '#D4AF37' }}>info@safarinetics.com</a> or call us at <strong>+254725499649</strong>.
      </p>
    </div>
  );
};

export default TermsOfService;
