import React, { useContext } from 'react';
import { motion } from 'framer-motion';
// import { FaEnvelope, FaGlobe } from 'react-icons/fa'; // Example icons, replace with desired icons
import { NavigationContext } from '../context/NavigationContext';

const apps = [
  {
    title: 'NETICS MAILER',
    description: 'Imagine reaching your audience with precision—what could be achieved with advanced email marketing tools?',
    feature: 'Advanced analytics for tracking email campaigns.',
    icon: '✉️', // Using FontAwesome icon
    link: 'https://website.neticsmailer.safarinetics.com/', // Example link, replace with actual links
    logo: 'https://website.neticsmailer.safarinetics.com/static/media/logo.c1db393d756859b2af3f.png', // logo
  },
  {
    title: 'SAFARI APP',
    description: 'How much easier would your life be if you could streamline all aspects of tour management in one place?',
    feature: 'Integrated booking and itinerary management.',
    icon: '🌍', // Using FontAwesome icon
    link: '/safari-app', // Example link, replace with actual links
    logo: null, // logo
  },
];

export default function Features() {
  const {appsRef} = useContext(NavigationContext)
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1.2 } },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  return (
    <section ref={appsRef}
      className="features-section"
      style={{
        backgroundColor: 'lightgrey',
        color: '#333',
        padding: '5rem 2rem',
        textAlign: 'center',
        // borderTop: '2px solid teal',
      }}
    >
      <div className="container">
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          style={{ marginBottom: '4rem' }}
        >
          <h2 style={{ fontSize: '3rem', marginBottom: '1rem', fontFamily: '"Playfair Display", serif', color: '#2A324B' }}>
            Our Apps
          </h2>
          <p style={{ fontSize: '1.25rem', lineHeight: '1.8', color: '#555', fontFamily: '"Montserrat", sans-serif' }}>
            Discover our innovative products designed to transform your travel experience.
          </p>
        </motion.div>
        <motion.div
          className="row"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {apps.map((app, index) => (
            <motion.div
              key={index}
              className="col-md-6 col-lg-6"
              style={{
                padding: '1rem',
                marginBottom: '2rem',
              }}
              variants={itemVariants}
            >
              <div
                className="feature-card text-light"
                style={{
                  backgroundColor: '#1A202C',
                  border: '1px solid grey',
                  borderRadius: '12px',
                  boxShadow: '0 6px 12px rgba(0, 0, 0, 0.4)',
                  padding: '2rem',
                  textAlign: 'center',
                  height: '100%',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = 'scale(1.05)';
                  e.currentTarget.style.boxShadow = '0 12px 24px rgba(0, 0, 0, 0.2)';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = 'scale(1)';
                  e.currentTarget.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.15)';
                }}
              >
                <div
                  className="feature-icon"
                  style={{
                    fontSize: '3rem',
                    marginBottom: '1rem',
                  }}
                >
                  {app?.logo ? <img width={100} src={app?.logo} alt={app.title}/> :
                  app?.icon}
                </div>
                <h3 style={{ color: '#D4AF37', fontSize: '1.5rem', marginBottom: '1rem', fontFamily: '"Playfair Display", serif' }}>
                  {app.title}
                </h3>
                <p style={{ fontSize: '1rem', color: '#FFFFFF', lineHeight: '1.6' }}>
                  {app.description}
                </p>
                <p style={{ fontSize: '1rem', color: '#D4AF37', marginTop: '1rem', fontWeight: 'bold' }}>
                  Main Feature: {app.feature}
                </p>
                <a
                  href={app.link}
                  style={{
                    display: 'inline-block',
                    marginTop: '1rem',
                    fontSize: '1rem',
                    textDecoration: 'none',
                    border: '1px solid #D4AF37',
                    borderRadius: '4px',
                    padding: '0.5rem 1rem',
                    transition: 'background-color 0.3s, color 0.3s',
                    color: '#D4AF37',
                  }}
                  className='btn btn-outline-warning'
                >
                  Learn More
                </a>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
}
