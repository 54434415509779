import React from 'react';

const AboutIntro = () => {
  return (
    <section
      style={{
        padding: '4rem 2rem',
        backgroundColor: '#FFFFFF', // Light Grey
        borderTop: '5px solid #D4AF37', // Golden top border for an elegant touch
      }}
    >
      <div
        style={{
          maxWidth: '1200px',
          margin: '0 auto',
          textAlign: 'center',
        }}
      >
        <h2
          style={{
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '2.5rem',
            color: '#2A324B', // Dark Gold
            marginBottom: '1rem',
          }}
        >
          Who We Are
        </h2>
        <p
          style={{
            fontFamily: 'Merriweather, serif',
            fontSize: '1.2rem',
            color: '#2A324B', // Dark Blue Russian
            lineHeight: '1.6',
            marginBottom: '2rem',
          }}
        >
          At Safari Netics, we are committed to revolutionizing the travel industry. Our mission is to empower tour operators and travel agencies with innovative solutions that enhance customer experiences and streamline operations.
        </p>
        <a
          href="/about" // Link to the About Us page
          style={{
            display: 'inline-block',
            padding: '0.75rem 1.5rem',
            backgroundColor: '#D4AF37', // Dark Gold
            color: '#FFFFFF', // White
            textDecoration: 'none',
            borderRadius: '5px',
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: '600',
            transition: 'background-color 0.3s',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = '#2A324B'; // Dark Blue Russian on hover
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = '#D4AF37'; // Dark Gold
          }}
        >
          Learn More
        </a>
      </div>
    </section>
  );
};

export default AboutIntro;
