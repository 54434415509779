import React from "react";
import { motion } from "framer-motion";
import  ContactInfo from './ContactInfo';
import ContactUsForm  from './ContactUsForm';
import {Link} from 'react-router-dom'
// import { NavigationContext } from "../context/NavigationContext";

const ContactUs = () => {
  // const {contactRef} = useContext(NavigationContext)
  const pageStyle = {
    // background: "linear-gradient(to bottom, #1d3557, #457b9d)",
    backgroundColor:'#ecf0f1',
    color: "#264653",
    padding: "50px 20px",
    fontFamily: "'Roboto', sans-serif",
  };

  const heroStyle = {
    textAlign: "center",
    padding: "40px 20px",
    color: "#264653",
  };



  return (
    <motion.div  style={pageStyle} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
      {/* Hero Section */}
      <section style={heroStyle}>
        <motion.h1 initial={{ y: -50, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0.2 }}>
          Get In Touch With Us
        </motion.h1>
        <motion.p initial={{ y: -30, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0.3 }}>
          Need help or have questions? Let us know how we can assist you!
        </motion.p>
      </section>

      {/* Contact Info and Form Side by Side */}
      <section className="container mt-5">
        <div className="row align-items-center">
          {/* Contact Info */}
          <div className="col-md-6 mb-4">
            <ContactInfo/>
          </div>


          {/* Contact Form */}
          <div className="col-md-6 mb-4">
            <ContactUsForm/>
            
          </div>
        </div>
      </section>
      <p className="text-center my-4 fst-italic" style={{color:'#004d40'}}>While we’re here to help, you might find quick answers in our <Link to='/faqs'>FAQs</Link>! Check them out while you wait!</p>
    </motion.div>
  );
};

export default ContactUs;
