import React, { createContext, useRef } from 'react';
import {  useNavigate } from 'react-router-dom';

export const NavigationContext = createContext();

export default function NavigationProvider({ children }) {
    const navigate = useNavigate();

    // Create refs for each section
    const homeRef = useRef(null);
    const appsRef = useRef(null);
    const solutionsRef = useRef(null);
    const aboutRef = useRef(null);
    const newletterRef = useRef(null);
    const contactRef = useRef(null);

    // Function to handle scrolling
    const scrollToSection = (sectionRef) => {
        sectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    function goToHome() {
        navigate('/');
        // Scroll to the top of the page (hero section)
        scrollToSection(homeRef);
    }

    function goToOurApps() {
        navigate('/');
        // Ensure to scroll to features section after navigation
        setTimeout(() => scrollToSection(appsRef), 0);
    }

    function goToOurSolutions() {
        navigate('/');
        // Ensure to scroll to pricing section after navigation
        setTimeout(() => scrollToSection(solutionsRef), 0);
    }

    function goToAbout() {
        navigate('/');
        // Ensure to scroll to about section after navigation
        setTimeout(() => scrollToSection(aboutRef), 0);
    }

    function goToNewsletterSignUp() {
        navigate('/');
        // Ensure to scroll to testimonies section after navigation
        setTimeout(() => scrollToSection(newletterRef), 0);
    }

    function goToContactUs() {
        navigate('/contact_us');
        // Ensure to scroll to contact section after navigation
        setTimeout(() => scrollToSection(contactRef), 0);
    }
    function goToFAQs() {
        navigate('/faqs');
        // Ensure to scroll to contact section after navigation
        // setTimeout(() => scrollToSection(contactRef), 0);
    }

    function goToFreeTrial() {
        // set free trial to selected plan to localStorage 
        navigate('/demo')
        // navigate to the a hero page where they proceed to the form wizard
        // after submission navigate to confirmation page rather than invoice with an option to login 

        // navigate('/faqs');

    }

    const contextData = {
        goToHome,
        goToOurSolutions,
        scrollToSection,
        homeRef,
        appsRef,
        solutionsRef,
        aboutRef,
        goToOurApps,
        goToAbout,
        contactRef,
        goToContactUs,
        newletterRef,
        goToNewsletterSignUp,
        goToFAQs,
        goToFreeTrial,
    };

    return (
        <NavigationContext.Provider value={contextData}>
            {children}
        </NavigationContext.Provider>
    );
}
