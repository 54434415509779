import React from 'react';
import { Link } from 'react-router-dom';
import { FaExclamationTriangle } from 'react-icons/fa';
// import 'bootstrap/dist/css/bootstrap.min.css';

const NoPage = () => {
  return (
    <div className="container-fluid text-center"
     style={{ paddingTop: '100px',height:'90vh',backgroundColor:'lightgrey'}}>
      <div className="row">
        <div className="col">
          <FaExclamationTriangle style={{ fontSize: '80px', color: 'red' }} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h1 className="display-1">404</h1>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h5>Page Not Found</h5>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col">
          <Link
            to="/"
            className="btn btn-success"
            style={{
              // backgroundColor: '#4caf50',
              // color: '#ffffff',
              // padding: '10px 20px',
              // textDecoration: 'none',
              // borderRadius: '5px',
            }}
          >
            Go to Homepage
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NoPage;
