import React from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
// import companyLogo from '../images/logo.png'; // Replace with your logo path

const mission = "Our mission is transforming the tour and travel industry through innovative solutions that elevate customer experiences and optimize operational efficiency.";
const vision = "Our vision is to be the leading provider of comprehensive safari management solutions globally, enabling tour and travel businesses to thrive in the competitive industry.";
const values = [
  {
    title: "Innovation",
    description: "We continuously strive to stay ahead of industry trends."
  },
  {
    title: "Integrity",
    description: " We uphold the highest standards of integrity in all our actions."
  },
  {
    title: "Customer Focus",
    description: "Our clients are at the heart of everything we do."
  },
  {
    title: "Collaboration",
    description: "We believe in the power of working together."
  },
  {
    title: "Commitment",
    description: "We are committed to revolutionizing the tour and travel industry."
  },
];
const overview = 'At Safari Netics, we are committed to revolutionizing the tour and travel industry. As a leading provider of innovative software solutions tailored for tourism, we empower tour operators and travel agencies with advanced technology designed to streamline processes and enhance customer experiences, driving success in the competitive travel industry'
const About = () => {
  return (
    <div>
      <Helmet>
        <title>About Us </title>
        <meta
          name="description"
          content="Discover Safari Netics: a leader in safari management software. Learn about our mission, vision, and core values in providing innovative solutions for tour and travel businesses."
        />
        <meta
          name="keywords"
          content="Safari Netics, safari management software, tour management, travel management, innovative software solutions, tour and travel business solutions"
        />
        <meta property="og:title" content="About Safari Netics | Safari Management Solutions" />
        <meta property="og:description" content="Discover Safari Netics: a leader in safari management software. Learn about our mission, vision, and core values in providing innovative solutions for tour and travel businesses." />
        <meta property="og:image" content="https://safarinetics.com/static/media/logo.1fa63220a364404d22b0.png" /> {/* Replace with the URL of your image */}
        <meta property="og:url" content="https://safarinetics.com/about" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Safari Netics" />
        <meta property="og:locale" content="en_US" />
        <link rel="canonical" href="https://safarinetics.com/about" />
      </Helmet>

      <section
        className="hero-section px-4"
        style={{
          backgroundColor: 'lightgrey', // Charcoal background
          paddingBottom: '2rem',
          paddingTop: '6rem',
          textAlign: 'center',
        }}
      >
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          {/* <img
            src={companyLogo}
            alt="Safari Netics Logo"
            style={{ width: '200px', marginBottom: '2rem' }}
          /> */}
          <h1
            style={{
              fontFamily: 'Georgia, serif',
              fontSize: '3.5rem',
              fontWeight: '700',
              color: '#D4AF37', // Gold text
              marginBottom: '1rem',
            }}
          >
            About Us
          </h1>
          <p
            style={{
              fontFamily: 'Arial, sans-serif',
              // fontSize: '1.3rem',
              maxWidth: '800px',
              margin: '0 auto',
              lineHeight: '1.6',
            }}
          >
          {overview}</p>
        </motion.div>
      </section>


      <section
        className="mission-section"
        style={{
          backgroundColor: '#F1F1F1', // Light gray background
          padding: '4rem 2rem',
        }}
      >
        <div
          className="container"
          style={{
            maxWidth: '1200px',
            margin: '0 auto',
            padding: '0 1rem',
          }}
        >
          <h2
            style={{
              fontFamily: 'Georgia, serif',
              fontSize: '2.5rem',
              fontWeight: '700',
              color: '#2A324B', // Dark gray
              marginBottom: '2rem',
              textAlign: 'center',
            }}
          >
            Our Mission
          </h2>
          <motion.p
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            style={{
              fontFamily: 'Arial, sans-serif',
              fontSize: '1.1rem',
              color: '#666666',
              lineHeight: '1.6',
              textAlign: 'center',
              maxWidth: '800px',
              margin: '0 auto',
            }}
          >
            {mission}
          </motion.p>
        </div>
      </section>

      <section
        className="vision-section"
        style={{
          backgroundColor: 'lightgrey',
          padding: '4rem 2rem',
        }}
      >
        <div
          className="container"
          style={{
            maxWidth: '1200px',
            margin: '0 auto',
            padding: '0 1rem',
          }}
        >
          <h2
            style={{
              fontFamily: 'Georgia, serif',
              fontSize: '2.5rem',
              fontWeight: '700',
              color: '#2A324B',
              marginBottom: '2rem',
              textAlign: 'center',
            }}
          >
            Our Vision
          </h2>
          <motion.p
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            style={{
              fontFamily: 'Arial, sans-serif',
              fontSize: '1.1rem',
              color: '#666666',
              lineHeight: '1.6',
              textAlign: 'center',
              maxWidth: '800px',
              margin: '0 auto',
            }}
          >
            {vision}
          </motion.p>
        </div>
      </section>

      <section
  className="values-section"
  style={{
    backgroundColor: '#F1F1F1',
    padding: '4rem 2rem',
  }}
>
  <div
    className="container"
    style={{
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '0 1rem',
    }}
  >
    <h2
      style={{
        fontFamily: 'Georgia, serif',
        fontSize: '2.5rem',
        fontWeight: '700',
        color: '#2A324B',
        marginBottom: '2rem',
        textAlign: 'center',
      }}
    >
      Our Values
    </h2>
    <motion.ul
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      style={{
        listStyleType: 'none',
        padding: '0',
        margin: '0 auto',
        maxWidth: '800px',
      }}
    >
      {values.map((value, index) => (
        <li
          key={index}
          style={{
            fontFamily: 'Arial, sans-serif',
            fontSize: '1.1rem',
            color: '#666666',
            lineHeight: '1.6',
            textAlign: 'center',
            marginBottom: '1rem',
          }}
        >
          <strong style={{ color: '#2C2C2C' }}>{value.title}:</strong> {value.description}
        </li>
      ))}
    </motion.ul>
  </div>
</section>
    </div>
  );
};

export default About;
