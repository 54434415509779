import React, { useContext } from 'react';
import videoBackground from '../videos/digital_background_loop.mp4'; // Replace with your video file
import { motion } from 'framer-motion';
// import logo from '../images/logo_nobg.png';
import { NavigationContext } from '../context/NavigationContext';

export default function Hero() {
  const {homeRef,goToOurApps} = useContext(NavigationContext)
  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 2.2 } },
  };

  const headingVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0, transition: { duration: 2.2, delay: 0.3 } },
  };

  const sloganVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 2.2, delay: 0.6 } },
  };

  const paragraphVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 3.2, delay: 1.6 } },
  };

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 3.5, ease: 'easeInOut' } },
  };

  return (
    <section ref={homeRef}
      className="hero-section"
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.07)',
        color: 'white',
        minHeight: '99vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        padding: '4rem',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <motion.video
        autoPlay
        loop
        muted
        playsInline
        className="video-background"
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: '-1',
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      >
        <source src={videoBackground} type="video/mp4" />
        Your browser does not support the video tag.
      </motion.video>
      <motion.div
        className="overlay"
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7))',
          zIndex: '0',
        }}
        variants={overlayVariants}
        initial="hidden"
        animate="visible"
      ></motion.div>
      <motion.div
        className="content-overlay"
        style={{ position: 'relative', zIndex: '1' }}
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              {/* Logo section (uncomment if needed) */}
              {/* <div style={{ position: 'fixed', right: 50, left: 50, top: 0 }}>
                <img
                  src={logo}
                  alt="Safari Netics Logo"
                  style={{ width: '100px' }}
                />
              </div> */}

              <motion.h1
                style={{
                  fontSize: '400%',
                  marginBottom: '1rem',
                  fontFamily: '"Garamond", serif',
                  color: '#D4AF37',
                  fontWeight: 'bold',
                }}
                variants={headingVariants}
                initial="hidden"
                animate="visible"
              >
                Safari Netics
              </motion.h1>
              <motion.p
                style={{
                  fontSize: '1.4rem',
                  lineHeight: '1.6',
                  marginBottom: '100%rem',
                  fontFamily: '"Montserrat", sans-serif',
                  color: '#E0E0E0',
                }}
                variants={sloganVariants}
                initial="hidden"
                animate="visible"
              >
                <span
                  style={{
                    color: '#D4AF37',
                    fontFamily: '"Garamond", serif',
                    // fontSize: '100%',
                    fontWeight: 'lighter',
                  }}
                >
                  Smart Tech, Smart Tours
                </span>
              </motion.p>
              <motion.div
                style={{
                  borderTop: '1px solid #FFD700',
                  width: '70%',
                  margin: 'auto',
                  paddingTop: '1rem',
                  color: '#F4F4F4',
                }}
                variants={paragraphVariants}
                initial="hidden"
                animate="visible"
              >
                <p style={{
                  fontFamily: '"Montserrat", sans-serif',
                  fontWeight: 'lighter',}}>
                  Empowering the tour and travel industry with innovative solutions that enhance customer experiences and streamline operations.
                </p>
              </motion.div>
              <motion.a
                href="#"
                style={{
                  display: 'inline-block',
                  marginTop: '2rem',
                  padding: '1rem 2rem',
                  backgroundColor: '#D4AF37',
                  color: '#fff',
                  borderRadius: '5px',
                  textDecoration: 'none',
                  fontSize: '1.5rem',
                }}
                onClick={goToOurApps}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                Explore Our Apps
              </motion.a>
            </div>
          </div>
        </div>
      </motion.div>
    </section>
  );
}
