import React, { useContext, useState} from 'react';
import { motion } from 'framer-motion';
import { FormsContext } from '../context/FormsContext';
import { NavigationContext } from '../context/NavigationContext';

export default function NewsletterSignup() {
  const {newletterRef} = useContext(NavigationContext)
  const {emailSubscription,loading} = useContext(FormsContext)
  const [name,setName] = useState('')
  const [email,setEmail] = useState('')
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(email){
       await emailSubscription({email,name})
    // console.table('Name:',name,'Email:',email)
    setName('')
    setEmail('')
    
  }
      
  
}

  return (
    <section ref={newletterRef} style={{ padding: '5rem 2rem', textAlign: 'center', backgroundColor: '#1A202C' }}>
      <motion.h2
        style={{
          fontSize: '3rem',
          fontFamily: '"Playfair Display", serif',
          color: '#D4AF37', // Forest Green for heading
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        Stay Updated
      </motion.h2>
      <motion.p
        style={{
          fontSize: '1.25rem',
          lineHeight: '1.8',
          color: '#FFF', // White text for description
          marginBottom: '4rem',
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.2 }}
      >
        Subscribe to our newsletter and never miss out on the latest updates and exclusive offers.
      </motion.p>
      <form onSubmit={handleSubmit} style={{ maxWidth: '600px', margin: '0 auto' }}>
        <div className="form-group">
          <input
            type="text"
            value={name}
            className="form-control"
            placeholder="Enter your name (optional)"
            onChange={(e)=> setName(e.target.value)}
            style={{
              padding: '0.75rem',
              borderRadius: '6px',
              marginBottom: '1rem',
              backgroundColor: 'wheat', // Turquoise for input background
              // color: '#FFF', // White text in input
              border: '1px solid #2A5D4D', // Forest Green border for inputs
            }}
          />
          <input
            type="email"
            className="form-control"
            placeholder="Enter your email"
            value={email}
            onChange={(e)=> setEmail(e.target.value)}
            style={{
              padding: '0.75rem',
              borderRadius: '6px',
              backgroundColor: 'wheat', // Turquoise for input background
              // color: '#FFF', // White text in input
              border: '1px solid #2A5D4D', // Forest Green border for inputs
            }}
          />
        </div>
        <motion.button
          type="submit"
          className="btn btn-outline-warning"
          style={{
            marginTop: '1rem',
            padding: '0.75rem 1.5rem',
            backgroundColor: '#2A5D4D', // Forest Green background for button
            border: 'none',
            color: '#FFF', // White text for button
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}
          initial={{ scale: 1 }}
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.2 }}
        >          
        {loading ?<div className="spinner-border" role="status"></div> : 'Submit'}
        </motion.button>
      </form>
    </section>
  );
}
