import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { FaUserCog} from 'react-icons/fa';
import { NavigationContext } from '../context/NavigationContext';

export default function Solutions() {
  const {solutionsRef} = useContext(NavigationContext)
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1.2 } },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  return (
    <section
    ref={solutionsRef}
      className="solutions-section"
      style={{
        backgroundColor: '#ffffff',
        padding: '4rem 1.5rem',
        textAlign: 'center',
      }}
    >
      <motion.div
        className="container"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <h2 style={{ fontSize: '2.5rem', marginBottom: '1.5rem', fontFamily: 'Playfair Display, serif', color: '#1A202C' }}>
          Tailored Solutions for Your Business
        </h2>
        <p style={{ fontSize: '1.2rem', lineHeight: '1.8', color: '#4A5568', fontFamily: 'Montserrat, sans-serif', marginBottom: '3rem' }}>
          Discover innovative tools designed to help you connect, engage, and grow in the tourism industry.
        </p>

        <div className="row" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
          {/* Solution Card */}
          {[
            {
              icon: '⚙️',
              title: "System Development",
              description: "Innovative system development to streamline your operations and improve efficiency.",
            },
            {
              icon: <FaUserCog />,
              title: "Tour Agent Portals",
              description: "Dedicated portals for managing email campaigns and analysis reports.",
            },
            {
              icon: '📈',
              title: "Analytics & Reporting",
              description: "Track performance with business intelligence dashboards and custom reports.",
            },
            {
              icon: '✉️',
              title: "Digital Marketing",
              description: "Tailored email marketing and data-driven insights to engage your audience.",
            },
            {
              icon: '🔐',
              title: "Security Solutions",
              description: "GDPR compliance, secure data storage, and bespoke software to protect your data.",
            },
            {
              icon: '👨‍🏫',
              title: "Training & Support",
              description: "Comprehensive training and support to ensure seamless use of our solutions.",
            },
            {
              icon: '👨‍💻',
              title: "Website Development",
              description: "Custom website design and development tailored to your business needs.",
            }
          ].map((solution, index) => (
            <motion.div
              className="col-md-4"
              key={index}
              variants={itemVariants}
              style={{ marginBottom: '2rem', flex: '1 0 30%', padding: '1rem' }}
            >
              <div
                className="solution-card"
                style={{
                  backgroundColor: '#1A202C',
                  color: '#fff',
                  padding: '2rem',
                  borderRadius: '12px',
                  textAlign: 'center',
                  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease',
                }}
              >
                <div style={{ fontSize: '3rem', marginBottom: '1rem', color: '#2D9CDB' }}>
                  {solution.icon}
                </div>
                <h3 style={{ color:'#D4AF37',fontSize: '1.5rem', marginBottom: '1rem', fontFamily: 'Playfair Display, serif' }}>
                  {solution.title}
                </h3>
                <p style={{ fontSize: '1rem', lineHeight: '1.6' }}>
                  {solution.description}
                </p>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </section>
  );
}
