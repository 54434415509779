import React from 'react';

const Services = () => {
  return (
    <div className="services-page">
      <header>
        <h1>Our Services</h1>
      </header>

      <section id="software-development">
        <h2>Software Development</h2>
        <p>
          We offer custom software solutions tailored for tour operators and travel agencies. Our development team works closely with clients to create user-friendly applications that meet their specific needs.
        </p>
      </section>

      <section id="integration-solutions">
        <h2>Integration Solutions</h2>
        <p>
          Our integration solutions allow you to seamlessly connect your existing systems with our technology, enhancing efficiency and data flow across your operations.
        </p>
      </section>

      <section id="support-maintenance">
        <h2>Support and Maintenance</h2>
        <p>
          We provide ongoing support and maintenance services to ensure that your systems run smoothly and efficiently, allowing you to focus on your business.
        </p>
      </section>

      <section id="consulting">
        <h2>Consulting Services</h2>
        <p>
          Our consulting services help clients optimize their tourism operations, leveraging technology to improve performance and customer satisfaction.
        </p>
      </section>

      <section id="mobile-apps">
        <h2>Mobile App Development</h2>
        <p>
          We specialize in developing mobile apps for tour operators, enhancing the travel experience for customers through intuitive interfaces, booking systems, and real-time notifications.
        </p>
      </section>

      <section id="data-analytics">
        <h2>Data Analytics</h2>
        <p>
          Our data analytics services help tour operators make data-driven decisions by providing insights into customer behavior, booking trends, and business performance.
        </p>
      </section>

      <section id="crm-solutions">
        <h2>Customer Relationship Management (CRM) Solutions</h2>
        <p>
          We offer CRM solutions designed for the tourism industry, helping tour operators manage customer relationships, track leads, and enhance communication to drive sales and satisfaction.
        </p>
      </section>

      <section id="payment-gateway">
        <h2>Payment Gateway Integration</h2>
        <p>
          Our payment gateway solutions allow seamless online payments for tour bookings, ensuring secure transactions and smooth financial management.
        </p>
      </section>

      <section id="custom-booking-platforms">
        <h2>Custom Booking Platforms</h2>
        <p>
          We design and develop custom booking platforms tailored to your specific business needs, enabling easy booking management and enhancing the customer experience.
        </p>
      </section>

      <footer>
        <p>&copy; 2025 Safari Netics. All rights reserved.</p>
        <a href="/">Home</a>
      </footer>
    </div>
  );
};

export default Services;
