import React, { useContext } from 'react';
import commingSoonGif from '../images/comingsoon.gif';
import { NavigationContext } from '../context/NavigationContext';

const SafariApp = () => {
    const {goToNewsletterSignUp} = useContext(NavigationContext)
  return (
    <div style={{ 
      minHeight: '80vh', 
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'center', 
      alignItems: 'center', 
      backgroundColor: '#f8f9fa', 
      textAlign: 'center', 
      paddingTop: '6rem' , 
      paddingBottom: '2rem' 
    }}>
      <h1 style={{ 
        fontSize: '3rem', 
        color: '#D4AF37', 
        marginBottom: '20px' 
      }}>
        SAFARI APP
      </h1>
      <img 
        src={commingSoonGif}
        alt="Coming Soon"
        style={{ 
          maxWidth: '100%', 
          height: 'auto', 
          borderRadius: '8px' 
        }} 
      />
      <p style={{ 
        fontSize: '1.5rem', 
        color: '#6c757d', 
        marginTop: '20px' 
      }}>
        We're working hard to bring you something amazing. Stay tuned!
      </p>
      <a 
        href="/" // Replace with the actual URL of your form
        onClick={goToNewsletterSignUp}
        style={{ 
          marginTop: '10px', 
          fontSize: '1.2rem', 
          color: 'blue', 
          textDecoration: 'underline' 
        }}
      >
        Fill out this form to be informed when it's ready!
      </a>
    </div>
  );
};

export default SafariApp;
