import React from 'react';
import { Helmet } from 'react-helmet';
import Hero from './Hero';
// import Testimonies from '../components/Testimonies';
import Apps from './Apps';
import AboutIntro from './AboutIntro';
import Solutions from './Solutions';
// import Blog from './Blog';
import NewsletterSignup from './NewsletterSignup';

export default function Home() {
  return (
    <>
      <Helmet>
        <title>Safari Netics - Effortless Safari Management</title>
        <meta
          name="description"
          content="Discover Safari Netics for sophisticated safari management and email marketing solutions. Your premier gateway to streamline safari and travel management and operations."
        />
        <meta
          name="keywords"
          content="Safari Netics, safari netics kenya, safari management, travel solutions, sophisticated software, wildlife tourism, email marketing"
        />
        <meta property="og:title" content="Safari Netics - Effortless Safari Management" />
        <meta property="og:description" content="Discover Safari Netics for sophisticated safari management and email marketing solutions." />
        <meta property="og:image" content="%PUBLIC_URL%/logo512.png" />
        <meta property="og:url" content="https://safarinetics.com/" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Safari Netics - Effortless Safari Management" />
        <meta name="twitter:description" content="Discover Safari Netics for sophisticated safari management and email marketing solutions with elegance and precision." />
        <meta name="twitter:image" content="%PUBLIC_URL%/logo512.png" />
        <link rel="canonical" href="https://safarinetics.com/" />
      </Helmet>
      <div>
        <Hero />
        <AboutIntro/>
        <Apps />
        <Solutions />
        {/* <Blog/> */}
        <NewsletterSignup/>
      </div>
    </>
  );
}
