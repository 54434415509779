import React, { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import { FaHome, FaInfoCircle, FaServicestack, FaQuestionCircle, FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { NavigationContext } from '../context/NavigationContext';

const Dashboard = () => {
  const {goToOurApps,goToOurSolutions,goToHome,goToContactUs} = useContext(NavigationContext)
  const [isVisible, setIsVisible] = useState(false);

  const toggleDashboard = () => {
    setIsVisible(!isVisible);
  };

  // Define animation settings for the links
  const linkVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1, // Delay each link's animation
        duration: 0.5,
        ease: 'easeOut',
      },
    }),
  };

  // Define animation settings for the sidebar
  const sidebarVariants = {
    hidden: { x: '-100%', opacity: 0 },
    visible: { x: 0, opacity: 1 },
    exit: { x: '-100%', opacity: 0, transition: { duration: 0.3 } },
  };

  const handleLinkClick = () => {
    setIsVisible(false); // Hide the dashboard when a link is clicked
  };

  return (
    <div style={styles.container}>
      {/* Toggle Button */}
      <button className='btn rounded' style={styles.toggleButton} onClick={toggleDashboard}>
        {isVisible ? <FaTimes /> : <FaBars />}
      </button>

      {/* Sidebar */}
      <motion.div
        initial="hidden"
        animate={isVisible ? "visible" : "hidden"}
        exit="exit"
        variants={sidebarVariants}
        style={styles.sidebar}
      >
        {/* Navigation Links */}
        <div style={styles.nav}>
          <motion.div
            variants={linkVariants}
            initial="hidden"
            animate="visible"
            custom={0}
          >
            <Link to="/" style={styles.link} onClick={() => {goToHome()
            handleLinkClick()}}><FaHome /> Home</Link>
          </motion.div>
          <motion.div
            variants={linkVariants}
            initial="hidden"
            animate="visible"
            custom={1}
          >
            <Link to="/about" style={styles.link} onClick={handleLinkClick}><FaInfoCircle /> About</Link>
          </motion.div>
          <motion.div
            variants={linkVariants}
            initial="hidden"
            animate="visible"
            custom={2}
          >
            <Link to="" style={styles.link} onClick={() => {
              handleLinkClick()
              goToOurApps()

            }}><FaServicestack /> Our Apps</Link>
          </motion.div>
          <motion.div
            variants={linkVariants}
            initial="hidden"
            animate="visible"
            custom={2}
          >
            <Link to="" style={styles.link} onClick={() => {
              handleLinkClick()
              goToOurSolutions()}}><FaServicestack /> Our Solutions</Link>
          </motion.div>
          <motion.div
            variants={linkVariants}
            initial="hidden"
            animate="visible"
            custom={3}
          >
            <Link to="/contact_us" style={styles.link} onClick={()=> {goToContactUs()
            handleLinkClick()}}><FaQuestionCircle /> Contact us</Link>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

const styles = {
  container: {
    position: 'relative', // Ensure the toggle button is positioned relative to this container
  },
  toggleButton: {
    position: 'fixed',
    top: '20px',
    left: '20px',
    fontSize: '24px',
    background: 'rgb(0,0,0,0.1)',
    color: '#fff',
    border: 'none',
    padding: '10px',
    cursor: 'pointer',
    zIndex: 1100, // Ensure it's above other elements
  },
  sidebar: {
    position: 'fixed',
    top: '20px', // Leave space from the top
    left: '20px', // Leave space from the left
    width: '250px',
    background: 'rgb(46,46,46,0.8)', // Dark gray background
    color: '#D4AF37', // Golden text color
    padding: '20px',
    boxShadow: '2px 0 5px rgba(0,0,0,0.2)',
    borderRadius: '8px',
    zIndex: 1000,
    height: 'auto', // Adjust based on content
    maxHeight: 'calc(100vh - 40px)', // Ensure it fits within the viewport
    overflowY: 'auto', // Allow scrolling if content overflows
  },
  nav: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    padding: '1rem 0'
  },
  link: {
    color: '#ffd700', // Golden text color
    textDecoration: 'none',
    fontSize: '18px', // Larger font size
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    padding: '10px 0',
    borderRadius: '4px',
    transition: 'background 0.3s, color 0.3s, transform 0.3s',
    // Added transform animation
  },
};

export default Dashboard;
