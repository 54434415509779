// App.js
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './layout/Layout';
import Home from './home/Home';
import About from './about/About';
import NoPage from './pages/NoPage';
import NavigationProvider from './context/NavigationContext';
import Services from './pages/Services';
import ContactUs from './contact/ContactUs';
import FormsProvider from './context/FormsContext';
import PrivacyPolicy from './documents/PrivacyPolicy';
import CookiesPolicy from './documents/CookiesPolicy';
import TermsOfService from './documents/TermaOfService';
import SafariApp from './pages/SafariApp';

export default function App() {
  return (
    <BrowserRouter>
      <NavigationProvider>
        <FormsProvider>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact_us" element={<ContactUs/>} />
            
            <Route path="/safari-app" element={<SafariApp/>} />
            <Route path="*" element={<NoPage />} />


            <Route path="/terms-of-service" element={<TermsOfService/>} />
            <Route path="/cookies-policy" element={<CookiesPolicy/>} />
            <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
          </Route>

          {/* Use SecondLayout for tours app routes */}
          {/* <Route path="/tours_app" element={<AppLayout />}>
            <Route index element={<ToursApp/>} /> */}
            {/* <Route path="/tours_app" element={<AppHome />} /> */}
            {/* <Route path="*" element={<NoPage />} /> */}
          {/* </Route> */}
        </Routes>
        </FormsProvider>
      </NavigationProvider>
    </BrowserRouter>
  );
}
