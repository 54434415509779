import React from 'react';
import { motion } from 'framer-motion';

export default function ContactInfo() {
  return (
    <motion.div
      className="d-flex flex-column gap-4"
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.6 }}
    >
      {/* Contact Info Card */}
      <div
        className="p-4"
        style={{
          background: 'linear-gradient(135deg, #1A202C, rgba(255, 215, 0, 0.7)), url("background_image.jpg")',
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          color: "white",
        }}
      >
        <div className="d-flex align-items-center gap-3">
          <div
            style={{
              background: "rgba(255, 215, 0, 0.4)", // Gold background for the icon
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "1.5rem",
              color: "#1A202C", // Russian Blue for the icon color
            }}
          >
            📞
          </div>
          <div>
            <h5 className="mb-1">Call Us or Whatsapp</h5>
            <p className="mb-0"><a className='text-decoration-none text-light' href='tel:+254725499649'>+254 725 499649</a></p>
          </div>
        </div>
      </div>

      {/* Email Info Card */}
      <div
        className="p-4"
        style={{
          background: 'linear-gradient(135deg, #1A202C, rgba(255, 215, 0, 0.7)), url("background_image.jpg")',
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          color: "white",
        }}
      >
        <div className="d-flex align-items-center gap-3">
          <div
            style={{
              background: "rgba(255, 215, 0, 0.4)", // Gold background for the icon
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "1.5rem",
              color: "#1A202C", // Russian Blue for the icon color
            }}
          >
            ✉️
          </div>
          <div>
            <h5 className="mb-1">Email Us</h5>
            <p className="mb-0"><a className='text-decoration-none text-light' href='mailto:info@safarinetics.com'>info@safarinetics.com</a></p>
          </div>
        </div>
      </div>

      {/* Working Hours Info Card */}
      <div
        className="p-4"
        style={{
          background: 'linear-gradient(135deg, #1A202C, rgba(255, 215, 0, 0.7)), url("background_image.jpg")',
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          color: "white",
        }}
      >
        <div className="d-flex align-items-center gap-3">
          <div
            style={{
              background: "rgba(255, 215, 0, 0.4)", // Gold background for the icon
              borderRadius: "50%",
              width: "50px",
              height: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "1.5rem",
              color: "#1A202C", // Russian Blue for the icon color
            }}
          >
            🕓
          </div>
          <div>
            <h5 className="mb-1">Working Hours</h5>
            <p className="mb-0">Mon - Fri: 9 AM - 6 PM</p>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
