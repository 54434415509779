import React, { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import { FormsContext } from '../context/FormsContext';

const ContactUsForm = () => {
  const {contactUS, loading} = useContext(FormsContext)
  const [formData, setFormData] = useState(
    JSON.parse(sessionStorage.getItem('contactUsForm')) || {
      name: '',
      email: '',
      subject: '',
      message: '',
    }
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);
    sessionStorage.setItem('contactUsForm', JSON.stringify(updatedFormData));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
   
      // Mock API submission logic
    const response = await contactUS(formData)
    if (response === 'success'){
      sessionStorage.removeItem('contactUsForm');
      setFormData({ name: '', email: '', subject: '', message: '' });
      
    }
      
  
}

  const formStyle = {
    maxWidth: '100%',
    minWidth: 'fit-content',
    margin: 'auto',
    padding: '20px',
    background: 'linear-gradient(135deg, rgba(255, 215, 0, 0.7), #1A202C), url("background_image.jpg")',
  };

  const inputStyle = {
    marginBottom: '15px',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    width: '100%',
  };

  const buttonStyle = {
    padding: '10px 15px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#007bff',
    color: '#fff',
    cursor: 'pointer',
    minWidth:'10rem'
  };

  return (
    <motion.div
      style={formStyle}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="container rounded shadow"
    >
      <h4 style={{ color: '#2C3E50' }} className="text-center mb-4">
        Send us a message
      </h4>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData?.name}
          onChange={handleChange}
          style={inputStyle}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Your Email"
          value={formData?.email}
          onChange={handleChange}
          style={inputStyle}
          required
        />
        <input
          type="text"
          name="subject"
          placeholder="Subject"
          value={formData?.subject}
          onChange={handleChange}
          style={inputStyle}
          required
        />
        <textarea
          name="message"
          placeholder="Your Message"
          value={formData?.message}
          onChange={handleChange}
          style={{ ...inputStyle, height: '100px' }}
          required
        />
        <button disabled={loading} type="submit" style={buttonStyle} className="btn btn-primary">
        {loading ?<div className="spinner-border" role="status"></div> : 'Send Message'}
        </button>
      </form>
    </motion.div>
  );
};

export default ContactUsForm;
