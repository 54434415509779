import React, { createContext , useState} from 'react'
import Swal from 'sweetalert2';
import axiosInstance from '../utils/RequestsQueue';

export const FormsContext = createContext()
export default function FormsProvider({children}) {

const [loading, setLoading] = useState(false)

    // handle contact us
    async function contactUS(formData) {

        setLoading(true)
        try {
          const response = await axiosInstance.requestWithQueue({
            url: `${process.env.REACT_APP_SERVER_URL}/contact/add`,//  // Adjusted the endpoint to match your server route
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            data: formData,
          });
    
          if (response?.data?.success) {
            Swal.fire({
              position: 'bottom-end',
              icon: 'success',
              title: 'Form submitted successfully!',
              showConfirmButton: false,
              timer: 1500,
              color: 'wheat',
              toast: true,
              background: '#228B22',
            });
            return 'success';
          }else{
            return 'None'
          }
        } catch (error) {
          console.error('Error Submiting the Contact us form:', error);
          
          Swal.fire({
            position: 'bottom-end',
            icon: 'error',
            text: error?.response?.data?.error,
            showConfirmButton: false,
            timer: 1500,
            toast: true,
            color: 'wheat',
            background: 'red',
          });
          return 'None'
          
        //   throw error; // Ensure error is thrown so it can be handled by higher-order functions
        }
        finally{
          setLoading(false)
        }
    }

    // handle email subscription
async function emailSubscription(formData) {

    setLoading(true)
    try {
      const response = await axiosInstance.requestWithQueue({
        url: `${process.env.REACT_APP_SERVER_URL}/emails/add/website?secret_key=${process.env.REACT_APP_NETICS_SECRET_KEY}`,//  // Adjusted the endpoint to match your server route
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: formData,
      });

      if ( response?.data?.success) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: response?.data?.success,
          showConfirmButton: false,
          timer: 1500,
          color: 'wheat',
          toast: true,
          background: '#228B22',
        });
        return 'success';
      }else{
        return 'None'
      }
    } catch (error) {
      console.error('Error Subcribing the Email:', error);
      
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        text: error?.response?.data?.error,
        showConfirmButton: false,
        timer: 1500,
        toast: true,
        color: 'wheat',
        background: 'red',
      });
      return 'None'
      
    //   throw error; // Ensure error is thrown so it can be handled by higher-order functions
    }
    finally{
      setLoading(false)
    }
}

    const contextData = {
        loading,setLoading,
        emailSubscription,
        contactUS,
    }
  return (
    <FormsContext.Provider value={contextData} >
        {children}
    </FormsContext.Provider>
  )
}
