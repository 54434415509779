import React from 'react'
import logo from '../images/logo_nobg.png'

export default function MainLogo() {
  return (
    
   <div style={{ position: 'fixed', top: 0, left: '50%', transform: 'translateX(-50%)', zIndex: 1000 }}>
   <img
     src={logo}
     alt="Safari Netics Logo"
     style={{ width: '100px', backgroundColor:'rgb(68,68,68,0.26)',zIndex:"1" }}
     className='rounded'
   />
</div>
  )
}
