import React from 'react';

const CookiesPolicy = () => {
  return (
    <div className="container my-5">
      <h2 className="text-uppercase text-center mb-4" style={{ color: '#D4AF37' }}>Cookies Policy</h2>
      <p style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
        This Cookies Policy explains how Safari Netics uses cookies to enhance your experience on our website.
      </p>

      <h5 className="mt-4">1. What are Cookies?</h5>
      <p>
        Cookies are small text files stored on your device by your web browser. They help us improve your experience by remembering your preferences and providing personalized content.
      </p>

      <h5 className="mt-4">2. Types of Cookies We Use</h5>
      <p>
        We use the following types of cookies:
      </p>
      <ul>
        <li><strong>Essential Cookies:</strong> Necessary for the operation of our website.</li>
        <li><strong>Performance Cookies:</strong> Help us understand how visitors interact with our website.</li>
        <li><strong>Functional Cookies:</strong> Allow us to remember your preferences.</li>
        <li><strong>Targeting Cookies:</strong> Used to deliver advertisements relevant to you.</li>
      </ul>

      <h5 className="mt-4">3. Managing Cookies</h5>
      <p>
        You can manage your cookie preferences through your browser settings. You can choose to accept or reject cookies, but please note that disabling cookies may affect the functionality of our website.
      </p>

      <h5 className="mt-4">4. Changes to This Policy</h5>
      <p>
        We may update this Cookies Policy from time to time. We will notify you of any significant changes via email or through a prominent notice on our website.
      </p>

      <h5 className="mt-4">5. Contact Information</h5>
      <p>
        For any inquiries regarding our cookie usage, please contact us at <a href="mailto:info@safarinetics.com" style={{ color: '#D4AF37' }}>info@safarinetics.com</a> or call <strong>+254725499649</strong>.
      </p>
    </div>
  );
};

export default CookiesPolicy;
