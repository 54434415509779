import React from 'react';
import { motion } from 'framer-motion';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <motion.footer
      className="bg-dark text-white py-5"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container">
        <div className="row">
          {/* Company Info Section */}
          <motion.div 
            className="col-md-4 mb-4"
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <h5 className="mb-4 text-uppercase">Safari Netics</h5>
            <p>
              <strong>Smart Tech, Smart Tours:</strong> Empowering Tourism with Innovative Solutions for Seamless Travel Experiences.
            </p>
            <ul className="list-unstyled">
              <li><a href="#about" className="text-light">About Us</a></li>
              <li><a href="#services" className="text-light">Services</a></li>
              <li><a href="#contact" className="text-light">Contact Us</a></li>
            </ul>
          </motion.div>

          {/* Quick Links Section */}
          <motion.div 
            className="col-md-4 mb-4"
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <h5 className="mb-4 text-uppercase">Quick Links</h5>
            <ul className="list-unstyled">
              <li><a href="#home" className="text-light">Home</a></li>
              <li><a href="#features" className="text-light">Features</a></li>
              <li><a href="#pricing" className="text-light">Pricing</a></li>
              <li><a href="#faq" className="text-light">FAQ</a></li>
            </ul>
          </motion.div>

          {/* Social Media Section */}
          <motion.div 
            className="col-md-4 mb-4"
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            <h5 className="mb-4 text-uppercase">Connect with Us</h5>
            <p>Follow us on social media for updates and special offers.</p>
            <ul className="list-inline">
              <li className="list-inline-item">
                <motion.a 
                  href="#facebook" 
                  className="text-light"
                  whileHover={{ scale: 1.2, rotate: 15 }}
                  transition={{ type: 'spring', stiffness: 300 }}
                >
                  <FaFacebookF />
                </motion.a>
              </li>
              <li className="list-inline-item">
                <motion.a 
                  href="#twitter" 
                  className="text-light"
                  whileHover={{ scale: 1.2, rotate: 15 }}
                  transition={{ type: 'spring', stiffness: 300 }}
                >
                  <FaTwitter />
                </motion.a>
              </li>
              <li className="list-inline-item">
                <motion.a 
                  href="#linkedin" 
                  className="text-light"
                  whileHover={{ scale: 1.2, rotate: 15 }}
                  transition={{ type: 'spring', stiffness: 300 }}
                >
                  <FaLinkedinIn />
                </motion.a>
              </li>
              <li className="list-inline-item">
                <motion.a 
                  href="#instagram" 
                  className="text-light"
                  whileHover={{ scale: 1.2, rotate: 15 }}
                  transition={{ type: 'spring', stiffness: 300 }}
                >
                  <FaInstagram />
                </motion.a>
              </li>
            </ul>
          </motion.div>
        </div>

        {/* Bottom Section */}
        <motion.div 
          className="row pt-4 mt-4" 
          style={{ borderTop: '2px solid #d4af37' }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.8 }}
        >
          <div className="col-md-6">
            <p className="small text-info">&copy; {new Date().getFullYear()} Safari Netics. All rights reserved.</p>
          </div>
          <div className="col-md-6">
            <ul className="list-inline text-md-end">
              <li className="list-inline-item"><a href="terms-of-service" className="text-light">Terms of Use</a></li>
              <li className="list-inline-item"><a href="privacy-policy" className="text-light">Privacy Policy</a></li>
              <li className="list-inline-item"><a href="cookies-policy" className="text-light">Cookie Policy</a></li>
            </ul>
          </div>
        </motion.div>
      </div>
    </motion.footer>
  );
}

export default Footer;
