import axios from 'axios';

class RequestQueue {
  constructor() {
    this.queue = [];
    this.isProcessing = false;
  }

  // Helper function to create a delay
  delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async processQueue() {
    if (this.isProcessing || this.queue.length === 0) {
      return;
    }

    this.isProcessing = true;

    while (this.queue.length > 0) {
      const { config, resolve, reject } = this.queue.shift();

      let attempts = 0;
      const MAX_ATTEMPTS = 3;

      while (attempts < MAX_ATTEMPTS) {
        try {
          // Check if the endpoint ends with /send
          const requestConfig =(config.url.endsWith('/test') || config.url.endsWith('/send')||config.url.endsWith('/register')|| config.url.endsWith('/message'))
            ? { ...config } // No timeout for /send, /test & /message
            : { ...config, timeout: 10000 }; // Set 10 seconds timeout

          const response = await axios(requestConfig);
          
          // Check if response.data is empty
          if (!response.data || (typeof response.data === 'string' && response.data.trim() === '')) {
            attempts++;
            // Delay before retrying
            await this.delay(2000);
            if (attempts === MAX_ATTEMPTS) {
              // Re-add to the back of the queue after max attempts
              this.queue.push({ config, resolve, reject });
            }
            continue; // Retry the request
          }

          resolve(response);
          break; // Exit the retry loop on success

        } catch (error) {
          if (error.code === 'ECONNABORTED') {
            // Re-add to the back of the queue in case of timeout
            this.queue.push({ config, resolve, reject });
          } else {
            reject(error);
          }
          break; // Exit the retry loop on error
        }
      }
    }

    this.isProcessing = false;
  }

  requestWithQueue(config) {
    return new Promise((resolve, reject) => {
      this.queue.push({ config, resolve, reject });
      this.processQueue();
    });
  }
}

const requestQueue = new RequestQueue();

const axiosInstance = {
  requestWithQueue: requestQueue.requestWithQueue.bind(requestQueue),
};

export default axiosInstance;
