import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container my-5">
      <h2 className="text-uppercase text-center mb-4" style={{ color: '#D4AF37' }}>Privacy Policy</h2>
      <p style={{ fontSize: '1.1rem', lineHeight: '1.6' }}>
        At Safari Netics, we value your privacy. This policy outlines how we collect, use, and protect your information.
      </p>

      <h5 className="mt-4">1. Information Collection</h5>
      <p>
        We may collect personal information such as your name, email address, phone number, and payment information when you use our services. We also collect non-personal information such as browser type, IP address, and usage data.
      </p>

      <h5 className="mt-4">2. Use of Information</h5>
      <p>
        Your information helps us provide better services, improve our website, and communicate with you effectively. We may use your information for:
      </p>
      <ul>
        <li>Providing and managing our services.</li>
        <li>Sending you newsletters and marketing communications.</li>
        <li>Responding to your inquiries and support requests.</li>
      </ul>

      <h5 className="mt-4">3. Information Sharing</h5>
      <p>
        We do not sell or rent your personal information to third parties. We may share your information with trusted partners to help us operate our website and provide services, subject to confidentiality agreements.
      </p>

      <h5 className="mt-4">4. Data Security</h5>
      <p>
        We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, loss, or misuse. However, no method of transmission over the internet is 100% secure.
      </p>

      <h5 className="mt-4">5. Your Rights</h5>
      <p>
        You have the right to access, correct, or delete your personal information. If you wish to exercise these rights, please contact us using the information below.
      </p>

      <h5 className="mt-4">6. Changes to This Policy</h5>
      <p>
        We may update this privacy policy from time to time. We will notify you of any significant changes via email or through a prominent notice on our website.
      </p>

      <h5 className="mt-4">7. Contact Information</h5>
      <p>
        For any questions regarding your privacy, please reach out at <a href="mailto:info@safarinetics.com" style={{ color: '#D4AF37' }}>info@safarinetics.com</a> or call <strong>+254725499649</strong>.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
