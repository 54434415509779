import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from './Footer'
import Dashboard from './Dashboad'
import MainLogo from '../components/MainLogo'

export default function Layout() {
  return (
    <div>
      <MainLogo/>
      <Dashboard/>
      <Outlet/>
      <Footer/>
    </div>
  )
}
